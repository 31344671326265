export default {
  state: {
    payment: false,
    paymentId: null
  },

  mutations: {
    changePaymentStatus(state, status) {
      state.payment = status
    },
    setPaymentId(state, id) {
      state.paymentId = id
    }
  },

  getters: {
    payment(state) {
      return state.payment
    },
    paymentId(state) {
      return state.paymentId
    }
  }
}
