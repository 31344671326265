export default {
  state: {
    opened: false,
    score: 0,
    result: false
  },

  mutations: {
    setScore(state, score) {
      state.score += score
    },
    anulScore(state) {
      state.score = 0
    },
    changeResultStatus(state, status) {
      state.result = status
    },
    changeOpenedStatus(state, status) {
      state.opened = status
    }
  },

  getters: {
    score(state) {
      return state.score
    },
    result(state) {
      return state.result
    },
    opened(state) {
      return state.opened
    }
  }
}
