import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router/index.js'
import '@/assets/styles/common.scss'

import VueYandexMetrika from 'vue-yandex-metrika'
import VueSlickCarousel from 'vue-slick-carousel'
import VueScrollTo from 'vue-scrollto'
import VueGtag from 'vue-gtag'

Vue.config.productionTip = false

Vue.use(VueYandexMetrika, {
  id: 85974130,
  router: router,
  env: process.env.NODE_ENV,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
  }
})

Vue.use(
  VueGtag,
  {
    config: { id: 'G-P4L303SH4P' }
  },
  router
)

Vue.component(VueSlickCarousel.name, VueSlickCarousel)
Vue.use(VueScrollTo)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
